import gql from "graphql-tag";
import userFragment from "./userFragment";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
    }
  }
`;

export const LOGIN_DIRECT_MUTATION = gql`
  mutation LoginDirect($email: String!, $password: String!) {
    loginDirect(email: $email, password: $password) {
      token
      user {
        ...${userFragment}
      }
    }
  }
`;

export const LOGIN_ROOT_MUTATION = gql`
  mutation LoginRoot($email: String!) {
    loginRoot(email: $email) {
      token
      user {
        ...${userFragment}
      }
    }
  }
`;
