<template>
  <div class="fill-heigt" fluid>
    <!-- <v-row>
      
        <div class="text-left " style="magin-top:3%">
          Painel de Clientes 
        </div>
     <v-spacer></v-spacer>
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
   
    </v-row> -->
     <v-row>
      <v-col cols="11" md="8">
        <div class="text-left subtitle" style="margin-top:3%">
          Painel de departamentos 
        </div>
      </v-col>
      
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
    <v-row>
      <v-col cols="12" md="12">
        <customer-table :entities="entities" />
      </v-col>  
    </v-row>  
  </div> 
</template>

<script>
import { ENTITIES_QUERY } from './../graphql/Query'
import CustomerTable from '../components/CustomerTable.vue'
export default {
  name: 'Entity',
  components: { CustomerTable },
  apollo: {
    entities: {
      query: ENTITIES_QUERY
    }
  }
}
</script>