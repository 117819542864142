<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="entities"
      class="elevation-1"
      dense
    >
      <template v-slot:[`item.logo`]="{ item }">
        <v-avatar size="32">
          <img
            :src="
              item.logo
                ? `${apiUrl}/images/entity/${item.logo}`
                : `/logo_default.png`
            "
            :alt="item.nmae"
          />
        </v-avatar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div class="text-center" :key="item.id">
          <v-switch
            v-model="item.status"
            :error="!item.status"
            :success="item.status"
            class="mt-1 ml-6 justify-end"
            dense
            hide-details
          ></v-switch>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-right" :key="item.id">
          <v-btn
            height="30px"
            color="blue"
            x-small
            class="ma-2"
            dark
            @click="openLoginConfirmeDialog(item.email, item.id)"
          >
            <v-icon small>mdi-wrench-outline</v-icon>
          </v-btn>
          <v-btn color="blue" x-small height="30px" class="ma-1" dark>
            <v-icon small>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn color="primary" x-small height="30px" class="ma-1" dark>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="error" x-small height="30px" class="ma-1" dark>
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="
        loginToEntity
          ? `Pretende logar na conta selecionada?`
          : `Pretende remover a entidade?`
      "
      @yes="loginToEntity ? loginToEntityAccount() : deleteEntity()"
      @no="confirm = false"
    />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import { mapActions } from "vuex";
import { LOGIN_ROOT_MUTATION } from "./../../../../auth/graphql/login";
import { GET_TRANSACTIONS_BY_ENTITY_ID } from "../graphql/Query";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import dialogMixins from "@/mixins/dialog";
export default {
  name: "CustomerTable",

  mixins: [dialogMixins],

  components: {
    ConfirmDialog,
  },

  props: {
    entities: Array,
  },

  data: () => ({
    apiUrl: API_URL,
    loginToEntity: false,
    entityId: null,
    skipQuery: true,
  }),

  apollo: {
    modules: {
      query: GET_TRANSACTIONS_BY_ENTITY_ID,
      variables() {
        return { entityId: this.entityId };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.setModules(data.transactions);
          localStorage.setItem("modulesL", JSON.stringify(data.transactions));
        }
      },
    },
  },

  computed: {
    headers() {
      return [
        { text: "", align: "center", sortable: false, value: "logo" },
        { text: "Nome", align: "start", sortable: false, value: "name" },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telephone" },
        { text: "Status", align: "center", value: "status" },
        { text: "Acções", align: "right", value: "actions", sortable: false },
      ];
    },
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),

    openLoginConfirmeDialog(email, entityId) {
      this.removeId = email;
      this.entityId = entityId;
      this.confirm = true;
      this.loginToEntity = true;
    },

    async loginToEntityAccount() {
      try {
        this.confirm = false;
        const { data } = await this.$apollo.mutate({
          mutation: LOGIN_ROOT_MUTATION,
          variables: {
            email: this.removeId,
          },
        });
        const id = data.loginRoot.user.id;
        const token = data.loginRoot.token;
        localStorage.removeItem(GC_USER_ID);
        localStorage.removeItem(GC_AUTH_TOKEN);
        this.saveUserData(id, token);
        this.setUserState(data.loginRoot.user);
        this.$router.push("/dashboard");
      } catch (error) {
        console.error(error);
      }
    },

    saveUserData(id, token) {
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
    },
  },
};
</script>